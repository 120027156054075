import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/styles.css';
import React from 'react';
import { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import { WrapRootElementBrowserArgs } from 'gatsby';
import ConsentBanner from './src/components/consent-banner';

export const onInitialClientRender = () => {
  if (!getCookieConsentValue()) {
    Cookies.set('gdpr-analytics-enabled', 'false');
  }
};

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs): JSX.Element => (
  <>
    {element}
    <ConsentBanner />
  </>
);